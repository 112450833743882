import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';
import './styles.css';

const formatarPontos = (celula, valor) => {
  return Number(valor.totalPontos).toLocaleString('pt-BR');
};

const formatarDataValidade = (celula, valor) => {
  return moment(valor.venceEm).format('DD/MM/YYYY HH:mm:ss');
};

const formatarData = (celula, valor) => {
  return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
};

const formatarDataCriacao = (celula, valor) => {
  return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
};

export default function Pontuacoes() {
  const columns = [
    {
      dataField: 'totalPontos',
      text: 'Pontos',
      formatter: formatarPontos,
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'pontuadoEm',
      text: 'Data da Pontuação',
      formatter: formatarData,
      sort: true,
    },
    {
      dataField: 'validadePontos',
      text: 'Vencimento da Pontuação',
      formatter: formatarDataValidade,
    },
    {
      dataField: 'linkArquivo',
      text: 'Foto',
      formatter: (celula, valor) => {
        return (
          <button
            style={{ border: 'none', background: 'none' }}
            onClick={() => {
              setShowModal(true);
              setLinkArquivo(valor.linkArquivo);
            }}
          >
            <i class="fas fa-image"></i>
          </button>
        );
      },
    },
  ];

  const columnsAnalise = [
    {
      dataField: 'criadoEm',
      text: 'Em análise desde',
      formatter: formatarDataCriacao,
      sort: true,
    },
    {
      dataField: 'linkArquivo',
      text: 'Foto',
      formatter: (celula, valor) => {
        return (
          <button
            style={{ border: 'none', background: 'none' }}
            onClick={() => {
              setShowModal(true);
              setLinkArquivo(valor.linkArquivo);
            }}
          >
            <i class="fas fa-image"></i>
          </button>
        );
      },
    },
  ];

  const columnsNaoProcessadas = [
    {
      dataField: 'dataEnvio',
      text: 'Data de Envio',
      formatter: (celular, valor) => moment(valor.dataEnvio).format('DD/MM/YYYY HH:mm:ss'),
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'reprovadoMotivo',
      text: 'Observação',
      sort: true,
    },
    {
      text: 'Foto',
      dataField: 'linkArquivo',
      formatter: (celula, valor) =>
        valor.linkArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              setLinkArquivo(valor.linkArquivo)
              setShowModal(true)
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
    },
  ];

  const columnsSwpro = [
    {
      dataField: 'totalPontos',
      text: 'Pontos',
      formatter: (celula, valor) => Number(valor.totalPontos).toLocaleString('pt-BR'),
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'pontuadoEm',
      text: 'Data Pontuação',
      formatter: (celula, valor) => moment(valor.pontuadoEm).format('DD/MM/YYYY'),
      sort: true,
    },
    {
      dataField: 'venceEm',
      text: 'Vencimento Pontuação',
      formatter: (celula, valor) => moment(valor.venceEm).format('DD/MM/YYYY'),
    },
    {
      dataField: 'indicadoId',
      text: 'Indicado',
      formatter: (celula, valor) => {
        if (!valor.indicadoId) return '-';
        return `ID ${valor.indicadoId}${valor.indicadoNome ? ` - ${valor.indicadoNome}` : ''}`;
      },
    },
    {
      dataField: 'plataforma',
      text: 'Plataforma',
      formatter: (celula, valor) => valor.plataforma || 'SW PRO Cursos',
      sort: true,
    },
  ];

  useEffect(() => {
    buscarPontuacoes();
    buscarAnalise();
    buscarNaoProcessadas();
    buscarPontuacoesSwpro();
  }, []);

  const [data, setData] = useState([]);
  const [dataAnalise, setDataAnalise] = useState([]);
  const [dataNaoProcessadas, setDataNaoProcessadas] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [linkArquivo, setLinkArquivo] = useState('');
  const [dataSwpro, setDataSwpro] = useState([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [pageAnalise, setPageAnalise] = useState(1);
  const [perPageAnalise, setPerPageAnalise] = useState(10);
  const [totalRecordsAnalise, setTotalRecordsAnalise] = useState(0);

  const buscarPontuacoes = async (currentPage = 1, currentPerPage = perPage) => {
    try {
      setBuscando(true);
      const resultado = await api.get('/api/pontuacoes/aprovadas', {
        params: {
          page: currentPage,
          perPage: currentPerPage
        }
      });
      
      if (resultado?.data?.pontuacoes) {
        setData(resultado.data.pontuacoes);
        setTotalRecords(resultado.data.totalRecords || 0);
        setPage(currentPage);
        setPerPage(currentPerPage);
        setDataAnalise([]);
      } else {
        setData([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error('Erro ao buscar pontuações:', error);
      setData([]);
      setTotalRecords(0);
    } finally {
      setBuscando(false);
    }
  };

  const buscarAnalise = async (currentPage = 1, currentPerPage = perPageAnalise) => {
    try {
      setBuscando(true);
      const resultado = await api.get('/api/pontuacoes', {
        params: {
          page: currentPage,
          perPage: currentPerPage,
          analise: true
        }
      });
      
      if (resultado?.data?.analise) {
        setDataAnalise(resultado.data.analise);
        setTotalRecordsAnalise(resultado.data.totalAnalise || 0);
        setPageAnalise(currentPage);
        setPerPageAnalise(currentPerPage);
      } else {
        setDataAnalise([]);
        setTotalRecordsAnalise(0);
      }
    } catch (error) {
      console.error('Erro ao buscar pontuações em análise:', error);
      setDataAnalise([]);
    } finally {
      setBuscando(false);
    }
  };

  const buscarNaoProcessadas = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/pontuacoes/nao-processadas');

    if (resultado) {
      setDataNaoProcessadas(resultado.data.naoProcessadas);
    }
    setBuscando(false);
  };

  const buscarPontuacoesSwpro = async () => {
    try {
      setBuscando(true);
      const resultado = await api.get('/api/pontuacoes/swpro');
      
      if (resultado?.data) {
        setDataSwpro(resultado.data.pontuacoesSwpro || []);
      }
    } catch (error) {
      console.error('Erro ao buscar pontuações SWPRO:', error);
    } finally {
      setBuscando(false);
    }
  };

  const options = {
    sizePerPage: perPage,
    hideSizePerPage: false,
    hidePageListOnlyOnePage: true,
    totalSize: totalRecords,
    page: page,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
    onPageChange: (newPage) => {
      buscarPontuacoes(newPage);
    },
    onSizePerPageChange: (newPerPage, newPage) => {
      buscarPontuacoes(newPage, newPerPage);
    },
    pageStartIndex: 1
  };

  return (
    <>
      <Container className="mt-5 mb-5">
        <div>
          <h5>PONTUAÇÕES</h5>
          <hr className="hr-loja mt-0" />
        </div>
        <div>
          <BootstrapTable
            remote={true}
            bootstrap4
            noDataIndication={() =>
              buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )
            }
            className="tabela-fundo-claro"
            keyField="id"
            data={Array.isArray(data) ? data : []}
            columns={columns}
            pagination={paginationFactory(options)}
            headerClasses="tabela-header-claro"
            bodyClasses="tabela-body-claro"
            onTableChange={(type, { page, sizePerPage }) => {
              if (type === 'pagination') {
                buscarPontuacoes(page, sizePerPage);
              }
            }}
          />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <h5>PONTUAÇÕES SWPRO</h5>
          <hr className="hr-loja mt-0" />
        </div>
        <div>
          <BootstrapTable
            bootstrap4
            noDataIndication={() =>
              buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )
            }
            className="tabela-fundo-claro"
            keyField="id"
            data={dataSwpro}
            columns={columnsSwpro}
            pagination={paginationFactory()}
            headerClasses="tabela-header-claro"
            bodyClasses="tabela-body-claro"
          />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <h5>EM ANÁLISE</h5>
          <hr className="hr-loja mt-0" />
        </div>
        <div>
          <BootstrapTable
            bootstrap4
            noDataIndication={() =>
              buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )
            }
            className="tabela-fundo-claro"
            keyField="id"
            data={dataAnalise}
            columns={columnsAnalise}
            pagination={paginationFactory()}
            headerClasses="tabela-header-claro"
            bodyClasses="tabela-body-claro"
          />
        </div>
        <div>
          <h5>NOTAS NÃO PROCESSADAS</h5>
          <hr className="hr-loja mt-0" />
        </div>
        <div>
          <BootstrapTable
            bootstrap4
            noDataIndication={() =>
              buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )
            }
            className="tabela-fundo-claro"
            keyField="id"
            data={dataNaoProcessadas}
            columns={columnsNaoProcessadas}
            pagination={paginationFactory()}
            headerClasses="tabela-header-claro"
            bodyClasses="tabela-body-claro"
          />
        </div>
      </Container>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {linkArquivo.toLowerCase().includes('.pdf') ? (
            <iframe
              width="100%"
              height={700}
              src={linkArquivo}
              frameborder="0"
            ></iframe>
          ) : (
            <img
              src={linkArquivo}
              alt=""
              style={{ maxWidth: 500, maxHeight: 500, objectFit: 'contain' }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
